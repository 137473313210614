body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  left: 800px;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.control {
  overflow: hidden;
  width: 400px;
  max-width: 100%;
}
.control {
  max-width: 400px;
  /* margin-bottom: 5px; */
  padding-bottom: 14px;
  position: relative;
}
.create {
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
}
.control label {
  display: block;
  /* margin-bottom: 5px; */
  text-align: left;
  font-weight: 500;
}
.create h2 {
  font-size: 25px;
  color: #810055;
  margin-bottom: 15px;
  /* margin-top: 15px; */
  border-bottom: 1px solid #f1f1ed;
  padding: 20px 40px;
}
.control input,
.create select {
  border: 1px solid #F1F1ED;
  border-radius: 5px;
  display: block;
  font-family: "G&B-Headline";
  font-size: 14px;
  height: 38px !important;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 10px;
  color: #333;
}
.control textarea,
.create select {
  border: 1px solid #F1F1ED;
  border-radius: 4px;
  display: block;
  font-family: inherit;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  margin: 10px 0;
  padding: 10px;
  color: #333;
}
.tabs-panel p + div {
  margin-top: 15px;
}
.tabs-panel.active {
  display: block;
}
.form-title{
  text-align: right;
  padding-right: 40%;
  margin-bottom: 2%;
}
.mb-2 input,
.create select,
.form-select {
  border: 1px solid #F1F1ED;
  border-radius: 5px;
  display: block;
  font-family: inherit;
  font-size: 14px;
  width: 50%;
  box-sizing: border-box;
  margin: 8px 0;
  padding: 8px;
  color: #333;
  height: 38px !important;
}
/* Navbar fix */
/* .sticky-top {
  position: fixed;
  width: 100%;
  top: 0;
} */
.main{
  margin-bottom: 100px;
}

span.input-group-text  {
  background-color: #f9f2f6 !important;
  color: #333333 !important;
  font-family: "G&B-Headline" !important;
  font-size: 14px !important;
  border-color: #F1F1ED !important;
}

input.form-control {
  font-size: 14px !important;
  font-family: "G&B-BodyCopy" !important;
  color: #333333 !important;
  background-color: #ffffff !important;
  border-color: #F1F1ED !important;
  height: 38px !important;
}

.form-check-input:checked {
  background-color: #33C037 !important;
  border-color: #33C037 !important;
  box-shadow: none;
}