
.LogbookForm {
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  /* font-weight: 600; */
  font-family: "G&B-BodyCopy";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
table {
  border:1px solid #F1F1ED;
  border-collapse: collapse;
}
Table {
  border:1px solid #F1F1ED;
  border-collapse: collapse;
}
input[type="checkbox"][readonly] {
  pointer-events: none;
}

input[type="checkbox"]{
  accent-color: #810055 !important;
  width: 18px !important;
  height: 18px !important;
}
.font-msg{
  font-family: "G&B-BodyCopy" !important;
  font-size: 14px !important;
}

#btnPrevNxt {
  background-color: rgb(13, 110, 253);
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #33C037;
  border-radius: 50%;
  display: inline-block;
}

.fa fa-circle-o {
  height: 25px;
  width: 25px;
  background-color: #33C037;
  border-radius: 50%;
  display: inline-block;
}

#progressbar{
  height: 12px;
  width: 250px; 
   border:1px solid #F1F1ED ;
  /* border-left: 1px solid #000;
border-right: 1px solid #000; */
};

/* table tr {
border-left: 1px solid #F1F1ED;
border-right: 1px solid #F1F1ED;
} */

/* table td {
  border-left: 1px solid #F1F1ED;
  border-right: 1px solid #F1F1ED;
}
table th {
  border-left: 1px solid #F1F1ED;
  border-right: 1px solid #F1F1ED;
}
Table tr {
  border-left: 1px solid #F1F1ED;
  border-right: 1px solid #F1F1ED;
  }
  
  Table td {
    border-left: 1px solid #F1F1ED;
    border-right: 1px solid #F1F1ED;
  }
  Table th {
    border-left: 1px solid #F1F1ED;
    border-right: 1px solid #F1F1ED;
  } */
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.center {
  margin: auto;
  width: 100%;
  border: 1px solid #F1F1ED;
  padding: 10px;
}

.FormHeader {
  text-align: center;
}

.FormButtons {
  display: flex;
  justify-content: center;
}

.FeedbackLabel {
  /* font-weight: 700; */
  font-size: 25;
}

.dropbtn {
  background-color: #04AA6D;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}
.dropbtn:hover, .dropbtn:focus {
  background-color: #33C037;
}
.required-field::before {
  content: "*";
  color: #E1142E;
}

h5.required-field {
  font-size: 18px;
  color: #333;
  font-family: 'G&B-BodyCopyHighlight';
  font-weight: normal;
  margin: 8px 0px !important;
}

#myInput {
  box-sizing: border-box;  
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #F1F1ED;
}

#ImpactofChangeswitch {
  width: 3em !important;
  height: 1.5em;
  margin-left: -2.5em;
  background-position: left center;
  border-radius: 5em;
  transition: background-position 0.15s ease-in-out;
  box-shadow: none !important;
}

#myInput:focus {outline: 3px solid #ddd;}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #F1F1ED;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.border {
  border: 2px blue dashed;
}

.mr-0 {
  margin-right: 0;
}
.ml-auto {
  margin-left:100%;
}
.d-block {
  display:block;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}


.paraheader {
  color: #333333 !important;
  font-family: 'G&B-BodyCopyHighlight' !important;
  font-size: 18px !important;
  padding: 16px 16px 0px 16px !important;
}

.Mainpara {
  border: 1px solid #F1F1ED !important;
  width: auto !important;
  margin: 0px 16px !important;
}

.subpara {
  font-family: 'G&B-BodyCopy'!important;
  font-size: 14px !important;
  color: #707070 !important;
  text-align: justify !important;
  padding: 0px 16px !important;
}

.feahometable th {
  color: #ffffff !important;
  background-color: #707070 !important;
  font-family: 'G&B-BodyCopyHighlight' !important;
  font-size: 14px !important;
}

.feahometable td {
  font-family: 'G&B-Headline'!important;
  color: #707070 !important;
  font-size: 14px !important;
}

.featd {
  color: #333 !important;
  font-family: 'G&B-BodyCopy' !important;
}
.form-title{
  color: #333 !important;
  font-family: 'G&B-BodyCopyHighlight' !important;
  font-size: 16px !important ;
  text-align: left !important;
}
.form label{
  color:#707070 !important;
  font-size: 14px !important;
  font-family: 'G&B-BodyCopy' !important;
}

.form-label{
  font-size: 14px !important;
  font-family: "G&B-BodyCopy" !important;
  color:#707070 !important;
}

.form-control:disabled,
.form-select:disabled {
  background-color: #F1F1ED !important;
  height: 38px;
    padding: 8px;
    color: #333;
    font-size: 14px;
}
.projectName{
  color:#707070 !important;
  font-size: 18px !important;
  font-family: 'G&B-BodyCopy' !important;
}
.form.nav-tabs{
  color: #810055 !important;
  background-color: #707070 !important;
  font-size: 14px !important;
}

.label-head{
  font-size: 16px !important;
  color: #333333 !important;
  font-family: "G&B-BodyCopyHighlight" !important;
}

label h5{
  font-size: 14px !important;
  font-family: "G&B-BodyCopy" !important;
}

.LogbookForm h2{
  color: #333 !important;
  font-size: 18px !important;
  font-family: "G&B-BodyCopyHighlight" !important;
}

span.font-weight-light{
  font-size: 16px !important;
  font-family: "G&B-BodyCopy" !important;
  color: #333333 !important;
}

span.files-note {
  font-size: 14px !important;
  color: #333333 !important;
  font-family: "G&B-BodyCopy" !important;
  text-align: center !important;
  /* margin: 10px !important; */
}

button.files-trash-icon {
  background-image: url("assets/img/icons/DeleteIcon.svg") !important;
  background-color: #ffffff !important;
  border: none !important;
  height: 24px !important;
  width: 24px !important;
  vertical-align: middle !important;
}

/* button.files-report-icon {
  background-image: url("assets/img/icons/Report_File.svg") !important;
  background-color: #ffffff !important;
  border: none !important;
  height: 24px !important;
  width: 24px !important;
  vertical-align: middle !important;
} */

textarea {
  border: 1px solid #f1f1ed !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  padding: 8px !important;
}

textarea:focus {
  border: 1px solid #333;
}

input.form-control:focus,
textarea.form-control:focus,
.form-select:focus {
  border: 1px solid #333333 !important;
  box-shadow: none !important;
}

.LogbookForm.container h3{
  font-family: "G&B-BodyCopyHighlight" !important;
  font-size: 18px !important;
  padding: 16px !important;
  color: #333333 !important;
}

.form-control:disabled, .form-select:disabled {
  background-color: #f1f1ed !important;
  font-size: 14px;
}

.closesidenavbar .active,
.closesidenavbar a:hover{
  background-color: #fff !important;
  box-shadow: none;
}

button.btn.btn-outline-danger {
    border-color: #E1142E;
    color: #E1142E;
    background-color: #fff;
    border-radius: 20px;
    padding: 4px 16px;
    height: 32px;
    font-family: 'G&B-BodyCopy';
}

